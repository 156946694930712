<template>
  <div class="policySearch">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/policy' }"
        >政策文库</el-breadcrumb-item
      >
      <el-breadcrumb-item>政策搜索</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="complete">
      <el-autocomplete
        v-model="searchText"
        :fetch-suggestions="querySearch"
        placeholder="请输入政策名称/项目名称"
        style="min-width: 666px"
        clearable
        @keyup.enter.native="keyupDown"
        @focus="searchFocus"
        ref="cautocomplete"
        @clear="clear"
        :debounce="0"
        :popper-append-to-body="false"
      >
        <template slot-scope="{ item }">
          <div style="display: flex">
            <div
              v-if="searchText"
              class="keyRecord"
              @click="handleNotice(item)"
              style="
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                flex: 1;
                width: 300px;
              "
            >
              {{ item.keyRecord }}
            </div>
            <div
              v-if="!searchText && !item.isHis"
              class="keyRecord"
              style="display: flex"
              @mouseenter="item.active = true"
              @mouseleave="item.active = false"
            >
              <div
                style="
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  flex: 1;
                  width: 550px;
                "
                @click="handleHistory(item)"
              >
                {{ item.keyword }}
              </div>
              <div
                v-if="item.active"
                @click="delHistory(item, $event)"
                class="keyRecord-icon"
              >
                <i class="el-icon-close"></i>
              </div>
            </div>
            <div
              v-if="!searchText && item.isHis"
              style="width: 100%; text-align: center; color: #156ed0"
              @click="handleDelAll"
            >
              {{ item.keyword }}
            </div>
            <div
              v-if="searchText && !item.isHis"
              class="policy"
              style="margin-left: 20px"
              @click="handleNotice(item)"
            >
              申报通知({{ item.noticeCount }})
            </div>
            <div
              v-if="searchText && !item.isHis"
              class="notice"
              style="margin-left: 20px"
              @click="handlePolicy(item)"
            >
              政策文件({{ item.policyCount }})
            </div>
          </div>
        </template>
        <el-button slot="append" @click="btnSearch">搜索</el-button>
      </el-autocomplete>
    </div>

    <div class="" v-loading="loading">
      <div
        style="
          display: flex;
          margin-top: 20px;
          cursor: pointer;
          font-size: 16px;
        "
      >
        <div
          style="
            margin-right: 30px;
            color: #156ed0;
            font-weight: 700;
            border-bottom: 2px solid #156ed0;
            padding-bottom: 10px;
            height: 21px;
          "
        >
          <!-- 申报通知 -->
          申报通知&nbsp;&nbsp;({{ totalNotice }})
        </div>
        <div @click="handleJumpReg" style="height: 41px">
          <!-- 政策文件 -->
          政策文件&nbsp;&nbsp;({{ totalReg }})
        </div>
      </div>
      <div class="screening">
        <div class="sortType">
          <div>
            排序方式：
            <el-radio-group
              v-model="sortType"
              size="mini"
              @change="handleToggleRadio"
            >
              <el-radio-button label="0">发文日期</el-radio-button>
              <el-radio-button label="1">截止日期</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="supportType">
          <div>
            扶持方式：
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ supportType
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in supportList"
                  :key="index"
                  @click.native="changeSupport(item)"
                >
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="declareType">
          <div>
            申报状态：
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ declareType
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in declareList"
                  :key="index"
                  @click.native="changeDeclare(item)"
                >
                  {{ item.title }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div>
        <div
          class="noticeList"
          style="margin-top: 50px"
          v-if="noticeList.length > 0"
          :style="total > 10 ? '' : 'padding-bottom:50px'"
        >
          <div v-for="(item, index) in noticeList" :key="index">
            <div
              style="font-size: 18px; font-weight: 700; cursor: pointer"
              @click="goToFormalDetail(item.id)"
              v-html="item.titleCopy"
            ></div>
            <div
              style="
                color: #b8b8b8;
                font-size: 14px;
                margin-top: 20px;
                margin-bottom: 20px;
              "
            >
              <span style="margin-right: 30px">{{ item.fwDepartment }}</span>
              <span>{{ item.dispatchDate }}</span>
            </div>
            <div
              class="formalFile"
              :style="index < noticeList.length - 1 ? 'margin-bottom:40px' : ''"
            >
              <div
                v-for="(v, i) in item.formalFile"
                :key="i"
                class="formalFile-item"
                v-show="i < item.num"
                @click="gotoJieDu(item.id, v.id)"
              >
                <div
                  :class="v.declareDate >= 0 ? 'red-title' : 'gray-title'"
                  style="width: 100px; margin-right: 15px"
                >
                  【{{
                    v.declareDate > 0 ? "剩" + v.declareDate + "天" : v.declareDate== 0 ? "今天截止" : "已截止"
                  }}】
                </div>
                <div style="flex: 1; display: flex; align-items: center">
                  <div
                    style="
                      margin-right: 20px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      max-width: 540px;
                    "
                    v-html="v.subTitleCopy"
                  ></div>
                  <div style="display: flex">
                    <div
                      v-for="(m, n) in v.fuchifangshi"
                      :key="n"
                      v-show="v.fuchifangshi.length > 0"
                      :class="[
                        'fuchifangshi',
                        { 'type-1': m === '资金扶持' },
                        { 'type-2': m === '股权投资' },
                        { 'type-3': m === '减税降费' },
                        { 'type-4': m === '称号认定' },
                        { 'type-5': m === '金融扶持' },
                        { 'type-6': m === '其他' },
                      ]"
                    >
                      {{ m }}
                    </div>
                    <div class="subsidies" v-if="v.money > 0">
                      最高补贴{{ v.money }}万
                    </div>
                  </div>
                </div>
                <div
                  style="font-size: 14px; color: #b8b8b8; margin-right: 10px"
                >
                  截止日期：{{ v.declareTime }}
                </div>
              </div>
              <div
                v-if="item.formalFile.length > 2"
                @click="changeFoldState(index)"
                style="
                  text-align: center;
                  color: #156ed0;
                  margin-top: 20px;
                  font-size: 14px;
                "
              >
                {{ item.brandFold }}
              </div>
            </div>
          </div>
        </div>

        <div class="without" v-else style="padding-bottom: 200px">
          <el-empty></el-empty>
          <div style="text-align: center">
            <span style="color: rgba(0, 0, 0, 0.6)">没有找到关于</span
            ><span style="color: #156ed0">"{{ noNoticeTitle }}"</span
            ><span style="color: rgba(0, 0, 0, 0.6)">的申报通知</span>
          </div>
        </div>
      </div>
      <el-pagination
        v-if="total > 10"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="queryParams.pageNo"
        @current-change="changeCurrent"
        style="margin-top: 50px"
      >
      </el-pagination>

      <permission
        v-if="dialogVisiblePermission"
        :dialogVisiblePermission="dialogVisiblePermission"
        @handleToggle="handleToggle"
        :typeDialog="typeDialog"
        @handlePermission="handlePermission"
      >
      </permission>
    </div>
  </div>
</template>

<script>
import { request } from "@/network";
import { setInfoData, getMemberId } from "@/assets/public/utils/token";
import permission from "@/components/pc/permission.vue";
import { getHeighLight } from "@/utils/heightLight";
import { mapState } from "vuex";

export default {
  components: {
    permission,
  },
  name: "SearchNotice",
  data() {
    return {
      searchText: "",
      keyWord: "",
      historyList: [],
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        sortType: 0,
        city: "",
        isEnd: "",
        keyWord: "",
        uuidList: "",
        memberId: getMemberId(),
      },
      noticeList: [],
      total: 0,
      pageNo: 1,
      sortType: "0",
      supportList: [],
      supportType: "",
      declareList: [
        { title: "不限", isEnd: "" },
        { title: "申报中", isEnd: false },
        { title: "已截止", isEnd: true },
      ],
      declareType: "",
      dialogVisiblePermission: false,
      typeDialog: "Vip",
      totalReg: 0,
      str: "",
      loading: false,
      totalNotice: 0,
      noNoticeTitle: "",
      showScreen: true,
      totalRegCopy: 0,
      searchResult: [],
      isFirst: true,
    };
  },
  activated() {
    this.init();
  },
  mounted() {
    if (!this.$route.query.page) {
      this.init();
    }
  },
  computed: {
    ...mapState({
      selectCity: (state) => state.menu.selectCity,
    }),
  },
  watch: {
    selectCity: {
      handler(val) {
        if (!this.isFirst) {
          this.queryParams.pageNo = 1;
          this.getList(val);
          this.getSearchCount();
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      const {
        keyWord,
        notice,
        policy,
        type,
        sortType,
        supportType,
        declareType,
        isEnd,
        uuidList,
      } = this.$route.query;
      this.searchText = keyWord;
      this.queryParams.keyWord = keyWord;
      this.str = keyWord;
      this.totalNotice = notice;
      this.totalReg = policy;
      if (type > 1) {
        this.sortType = sortType;
        this.supportType = supportType;
        this.declareType = declareType;
        this.queryParams.isEnd = isEnd;
        this.queryParams.uuidList = uuidList;
        this.queryParams.sortType = sortType || 0;
      } else {
        this.supportType = "不限";
        this.declareType = this.declareList[0].title;
      }
      this.getList();
      this.getTags();
      this.getSearchCount();
    },
    // 获取申报通知
    async getList(val) {
      this.loading = true;
      this.queryParams.city =
        val || localStorage.getItem("selectCity") || "广州市"; // incoming 删除
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/policylibrary/getNotice",
        data: this.queryParams,
      });
      const isDetailBack = JSON.parse(localStorage.getItem("isDetailBack")); // 如果是detail页面跳转回来的话就不回到顶部
      if (!this.isFirst && !isDetailBack) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      localStorage.setItem("isDetailBack", false);
      // incoming
      // const { data: res } = await request({
      //   method: 'POST',
      //   url: '/pcp/policylibrary/getNotice',
      //   data: {
      //       city: this.queryParams.city,
      //       isEnd: this.queryParams.isEnd?this.queryParams.isEnd:'',
      //       keyWord: this.queryParams.keyWord,
      //       memberId: this.queryParams.memberId,
      //       pageNo: this.queryParams.pageNo,
      //       pageSize: this.queryParams.pageSize,
      //       sortType: this.queryParams.sortType?this.queryParams.sortType:0
      //   }
      // })
      // incoming
      this.isFirst = false;
      // 修改搜索词的时候不刷新地改变url
      if (this.$route.query.keyWord !== this.queryParams.keyWord) {
        let arr = window.location.search.split("&");
        arr[0] = "?keyWord=" + this.queryParams.keyWord;
        this.$router.push(this.$route.path + arr.join("&"));
      }
      if (res.code !== 200) return this.$message.error("数据出错，请刷新");
      // 文字高亮
      const str = this.searchText;
      const { policyList, totalSize } = res.data;
      policyList.forEach((item) => {
        item.titleCopy = str ? getHeighLight(str, item.title) : item.title;
        item.formalFile.forEach((sub) => {
          sub.subTitleCopy = str
            ? getHeighLight(str, sub.subtitle)
            : sub.subtitle;
        });
        item.num = 2;
        item.brandFold = "+展开更多";
      });
      this.noticeList = policyList;
      this.total = totalSize;
      this.noNoticeTitle = this.queryParams.keyWord;
      this.loading = false;
    },
    async getSearchCount() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/librarysearch/getSearchCount",
        data: {
          city: localStorage.getItem("selectCity"),
          keyWord: this.queryParams.keyWord,
          uuidList: this.queryParams.uuidList,
          isEnd: this.queryParams.isEnd,
        },
      });
      const {
        code,
        data: { notice, policy },
      } = res;
      if (code !== 200) return this.$message.error("数据出错，请刷新");
      this.totalNotice = notice;
      this.totalReg = policy;
      this.totalRegCopy = policy;
    },
    // 扶持方式
    async getTags() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/policylibrary/getTags",
      });
      if (res.code !== 200) return this.$message.error("扶持方式获取失败");
      const arr = [{ id: "", name: "不限", uuid: "" }];
      this.supportList = arr.concat(res.data[0].children);
    },
    querySearch(queryString, cb) {
      if (queryString) {
        request({
          method: "POST",
          url: "/pcp/librarysearch/getSearchHistory",
          data: {
            keyWord: queryString,
            city: localStorage.getItem("selectCity"),
            memberId: getMemberId(),
          },
        }).then((res) => {
          if (res.data.code !== 200) return this.$message.error("暂无数据");
          const result = res.data.data;
          result.map((item, index) => {
            item.value = item.keyRecord;
          });
          this.searchResult = result;
          cb(result);
        });
      } else {
        request({
          method: "GET",
          url: "/pcp/librarysearch/getMemberSearchHistory",
          params: {
            memberId: getMemberId(),
          },
        }).then((response) => {
          if (response.data.code !== 200)
            return this.$message.error("暂无数据");
          let result = [];
          const arr = [{ keyword: "清除全部历史", isHis: true }];
          response.data.data.map((item, index) => {
            item.value = item.keyword;
            item.active = false;
          });
          result =
            response.data.data.length > 0
              ? response.data.data.concat(arr)
              : response.data.data;
          this.historyList = response.data.data;
          cb(result);
        });
      }
    },

    handleNotice(item) {
      this.searchText = item.keyRecord;
      this.handleParam();
      this.getList().then(() => {
        this.getSearchCount();
      });
    },
    handlePolicy(item) {
      const { sortType, uuidList, isEnd } = this.queryParams;
      request({
        method: "POST",
        url: "/pcp/librarysearch/getSearchCount",
        data: {
          city: localStorage.getItem("selectCity"),
          keyWord: item.keyRecord,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$router.push({
            path: "/policySearchReg",
            query: {
              keyWord: item.keyRecord,
              notice: res.data.data.notice,
              policy: res.data.data.policy,
              sortType: sortType,
              uuidList: uuidList,
              isEnd: isEnd,
              supportType: this.supportType,
              declareType: this.declareType,
            },
          });
        }
      });
    },

    // 点击历史记录
    handleHistory(item) {
      this.searchText = item.keyword;
      this.handleParam();
      this.getList().then(() => {
        this.getSearchCount();
      });
    },
    handleParam() {
      this.str = this.searchText;
      this.queryParams = {
        pageSize: 10,
        pageNo: 1,
        sortType: 0,
        city: localStorage.getItem("selectCity"),
        isEnd: "",
        keyWord: this.searchText,
        uuidList: "",
        memberId: getMemberId(),
      };
      this.sortType = "0";
      this.supportType = "不限";
      this.declareType = "不限";
    },
    searchFocus() {
      const selectBox = document.getElementsByClassName(
        "el-autocomplete-suggestion"
      )[0];
      selectBox.style.removeProperty("display");
    },
    btnSearch() {
      const selectBox = document.getElementsByClassName(
        "el-autocomplete-suggestion"
      )[0];
      selectBox.style.display = "none";
      // this.str = this.searchText
      // this.queryParams.keyWord = this.searchText
      // this.queryParams.pageNo = 1
      // this.queryParams.sortType = '0'
      // this.queryParams.uuidList = ''
      // this.queryParams.isEnd = ''
      // this.sortType = '0'
      // this.supportType = '不限'
      // this.declareType = '不限'
      this.handleParam();
      this.getList().then(() => {
        this.getSearchCount();
      });
    },
    // 删除历史记录
    delHistory(item, event) {
      event = event || window.event;
      event.cancelBubble = true;
      const dom = this.$refs.cautocomplete;
      const arr = [];
      arr.push(item.id);
      request({
        method: "POST",
        url: "/pcp/librarysearch/deleteMemberSearchHistory",
        data: {
          ids: arr,
        },
      }).then((res) => {
        if (res.data.code !== 200) return this.$message.error("删除失败");
        this.$message.success("删除成功");
        // this.searchText = ''
        dom.focus();
      });
    },
    // 删除全部历史记录
    handleDelAll() {
      const arr = [];
      this.historyList.forEach((v) => {
        arr.push(v.id);
      });
      request({
        method: "POST",
        url: "/pcp/librarysearch/deleteMemberSearchHistory",
        data: {
          ids: arr,
        },
      }).then((res) => {
        if (res.data.code !== 200) return this.$message.error("删除失败");
        this.$message.success("删除成功");
      });
    },

    // 点击申报状态
    changeDeclare(item) {
      this.declareType = item.title;
      this.queryParams.isEnd = item.isEnd;
      this.queryParams.pageNo = 1;
      this.getList().then(() => {
        this.getSearchCount();
      });
    },

    // 点击扶持方式
    changeSupport(item) {
      this.supportType = item.name;
      this.queryParams.uuidList = item.uuid ? item.uuid : undefined;
      this.queryParams.pageNo = 1;
      this.getList().then(() => {
        this.getSearchCount();
      });
    },
    // 点击排序方式
    handleToggleRadio(val) {
      this.queryParams.sortType = val;
      this.queryParams.pageNo = 1;
      this.getList();
      // this.getList().then(()=>{
      //     this.getSearchCount()
      // })
    },
    changeCurrent(val) {
      this.queryParams.pageNo = val;
      this.getList();
    },

    // 列表展开收起
    changeFoldState(index) {
      if (this.noticeList[index].brandFold === "+展开更多") {
        this.noticeList[index].num = this.noticeList[index].formalFile.length;
        this.noticeList[index].brandFold = "-收起";
      } else {
        this.noticeList[index].num = 2;
        this.noticeList[index].brandFold = "+展开更多";
      }
    },
    handleToggle() {
      this.dialogVisiblePermission = !this.dialogVisiblePermission;
    },

    handlePermission(type) {
      if (type === "Login") {
        this.$router.push({ name: "login" });
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
      }
    },
    goToFormalDetail(id) {
      this.$router.push({
        path: "/formaldetails",
        query: {
          id,
        },
      });
    },
    gotoJieDu(id, inputId) {
      setInfoData(request).then((resLogin) => {
        if (resLogin !== "islogin") {
          this.dialogVisiblePermission = !this.dialogVisiblePermission;
          this.typeDialog = "Login";
        } else {
          this.$router.push({
            path: "/formaldetails/jiedu",
            query: {
              id,
              inputId,
            },
          });
        }
      });
    },
    handleJumpReg() {
      const { sortType, uuidList, isEnd } = this.queryParams;
      let policy = 0;
      policy = this.totalRegCopy ? this.totalRegCopy : this.totalReg;
      this.$router.push({
        path: "/policySearchReg",
        query: {
          keyWord: this.searchText,
          notice: this.totalNotice,
          policy: policy,
          sortType: sortType,
          uuidList: uuidList,
          isEnd: isEnd,
          supportType: this.supportType,
          declareType: this.declareType,
        },
      });
    },
    keyupDown() {
      // this.str = this.searchText
      // this.queryParams.keyWord = this.searchText
      // this.queryParams.pageNo = 1
      // this.queryParams.sortType = '0'
      // this.queryParams.uuidList = ''
      // this.queryParams.isEnd = ''
      // this.sortType = '0'
      // this.supportType = '不限'
      // this.declareType = '不限'
      this.handleParam();
      // 取消聚焦
      document.activeElement.blur();
      // 隐藏下拉框
      this.$refs.cautocomplete.suggestions = [];
      this.getList().then(() => {
        this.getSearchCount();
      });
    },
    // 清除
    clear() {
      this.$refs.cautocomplete.activated = true;
      this.searchText = "";
      this.handleParam();
      // this.str = ''
      // this.queryParams.pageNo = 1
      // this.queryParams.keyWord = ''
      // this.queryParams.sortType = '0'
      // this.queryParams.uuidList = ''
      // this.queryParams.isEnd = ''
      // this.sortType = '0'
      // this.supportType = '不限'
      // this.declareType = '不限'
      // this.getList().then(() => {
      //   this.getSearchCount().then(() => {
      //     this.totalReg = this.totalRegCopy
      //   })
      // })
    },
  },
};
</script>

<style lang="less" scoped>
.policySearch {
  background-color: #fff;
  padding: 43px 0px 0 0px;

  .search {
    margin-top: 30px;
  }
}

.complete {
  margin-top: 30px;
}

/deep/.el-tabs__nav-wrap::after {
  display: none !important;
}

/deep/.el-tabs__active-bar {
  background-color: #156ed0;
}

/deep/.el-tabs__item.is-active {
  color: #156ed0;
}

/deep/.el-input__inner {
  height: 52px;
  line-height: 52px;
}

/deep/.el-input-group__append {
  background-color: #156ed0;
  color: #fff;
  border: 1px solid #156ed0;
}

.formalFile-item {
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  margin: 5px 0px 5px 0px;
  padding-left: 15px;
  color: #585858;
  box-sizing: border-box;
  cursor: pointer;
  background: rgb(248, 248, 248);
  border: 1px solid rgb(231, 231, 231);
  display: flex;
  align-items: center;

  .subsidies {
    border: 1px solid #ffa142;
    color: #ffa142 !important;
    font-size: 14px;
    border-radius: 3px;
    line-height: 20px;
    height: 20px;
    line-height: 20px;
    padding: 3px 5px 3px 5px;
  }
}

.red-title {
  color: red;
  font-size: 14px;
}

.gray-title {
  color: gray;
  font-size: 14px;
}

.type-1 {
  background: #fb9435;
}

.type-2 {
  background: #1f88f9;
}

.type-3 {
  background: #bf382f;
}

.type-4 {
  background: #2ebc66;
}

.type-5 {
  background: #6cc2ed;
}
.type-6 {
  background: rgba(0, 0, 0, 0.4);
}

.fuchifangshi {
  font-size: 14px;
  border-radius: 3px;
  line-height: 20px;
  height: 20px;
  line-height: 20px;
  padding: 3px 5px 3px 5px;
  color: #ffffff;
  margin-right: 15px;
}

.el-pagination {
  text-align: center;
  padding-bottom: 50px;
}

.screening {
  display: flex;
  font-size: 14px;
  margin-bottom: 50px;
  margin-top: 30px;
  align-items: center;

  & > div {
    margin-right: 50px;

    /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #fff;
      color: #156ed0;
      border-color: #156ed0;
    }

    /deep/.el-radio-button__inner:hover {
      color: #156ed0;
    }
  }
}

.keyRecord:hover {
  color: #156ed0;
}

.keyRecord {
  width: 100%;
  padding: 0 20px;
}

.policy:hover {
  color: #156ed0;
}

.notice {
  margin-right: 20px;
}

.notice:hover {
  color: #156ed0;
}

.noticeList-item:hover {
  border-bottom: 1px solid #000;
}

/deep/.el-empty__description p {
  display: none;
}
/deep/.el-empty {
  padding: 0;
}
/deep/.el-autocomplete-suggestion li {
  padding: 0 !important;
}
</style>
